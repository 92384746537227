.account-setup-page{
    display: flex;
}

.account-setup-content{
    width: 100%;
}

.account-content-container{
    width: 80%;
    margin: 0 auto;
}

.account-content-container h3{
    color: #18181B;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 8em 0 4.5em 0;
}

.select-user-type{
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
}

.select-musician, .select-curator{
    width: max(250px, 45%);
    border-radius: 10px;
    border: 2px solid #9B9FA3;
    background: #FFF;
    cursor: pointer;
}

.select-musician img, .select-curator img{
    display: block;
    margin: 16px auto;
}

.select-musician h4, .select-curator h4{
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 16px;
}

.select-musician p, .select-curator p{
    color: #9B9FA3;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 8px;
}

.select-musician .select, .select-curator .select{
    width: 18px;
    height: 18px;
    border: 2px solid #9B9FA3;
    border-radius: 50%;
    margin: 3em auto;
}

.curator-active, 
.musician-active,
.select-musician .select-active,
.select-curator .select-active{
    border: 2px solid #FF614D;
}

.curator-active, 
.musician-active{
    background-color: #FBEBE9;
}

.select-musician .select-active,
.select-curator .select-active{
    background-color: #fff;
}

.continue-back-btns{
    margin: 8em auto;
}

.btn-container{
    display: flex;
    width: max(500px, 50%);
    margin: 0 auto;
    gap: 2em;
}

.btn-container button{
    width: 230px;
    border: 1px solid #FF614D;
    color: #FF614D;
    background-color: transparent;
    padding: 24px 0;
    border-radius: 8px;
    cursor: pointer;
}

.btn-container .btn-active{
    background-color: #FF614D;
    color: #FFF;
    cursor: pointer;
}