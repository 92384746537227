.finalize-create-blog-campaign {
  background-color: white;
  padding: 20px;
}

.finalize-create-blog-campaign .finalize-header {
  flex-direction: column;
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  line-height: 140%;
  font-size: 20px;
  margin-block-end: 30px;
}

.finalize-create-blog-campaign .finalize-header .add-more-platforms-btn {
  border-radius: 8px;
  border: 2px solid #FF614D;
  color: #FF614D;
  padding: 20px 35px;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.finalize-create-blog-campaign .chosen-platforms-container {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));  */
  gap: 16px;
}

.finalize-create-blog-campaign .bottom-part-payment {
  margin-top: 70px;
  color: #000;
  font-family: "DM Sans", sans-serif;
}

.finalize-create-blog-campaign .bottom-part-payment .heading {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 32px;
}

.finalize-create-blog-campaign .bottom-part-payment .summary-container {
  border-radius: 16px;
  border: 1px solid #FAF4F4;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  margin-top: 32px;
}

.finalize-create-blog-campaign .bottom-part-payment .summary-container .head-desc {
  padding: 38px;
  padding-bottom: 14px;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  border-bottom: 1px solid #EBEAEA;
}

.finalize-create-blog-campaign .bottom-part-payment .summary-container .summary-body {
  padding: 30px 18px;
}

.finalize-create-blog-campaign .bottom-part-payment table {
  width: 100%;
  max-width: 700px;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0px;
}

.finalize-create-blog-campaign .bottom-part-payment table td {
  padding: 10px;
  color: #2E302F;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
}

.finalize-create-blog-campaign .bottom-part-payment .launch-campaign-btn {
  padding: 24px 12px;
  width: 100%;
  color: white;
  border-radius: 8px;
  background: #FF614D;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 40px;
}

@media (min-width: 750px) {
  .finalize-create-blog-campaign {
    padding: 60px;
  }

  .finalize-create-blog-campaign .finalize-header {
    flex-direction: row;
  }
}