.blogger-support {
  min-height: 100vh;
  background-color: #FAFAFA;
}

.blogger-support .blogger-support-main .body {
  padding: 18px;
}

.blogger-support .blogger-support-main .heading {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
}

.blogger-support .support-container {
  max-width: 528px;
  text-align: center;
  gap: 24px;
  margin: 63px auto 200px;
  color: #2E302F;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.blogger-support .support-container .create-ticket-btn {
  border-radius: 8px;
  background-color: #FEE9DE;
  color: #FF614D;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  padding: 20px 40px;
  text-align: center;
}

.blogger-support .support-action-btn-container {
  display: flex;
  max-width: 700px;
  justify-content: space-between;
  gap: 10px;
  margin: 0 auto 200px;
}

.blogger-support .support-action-btn .icon-container {
  width: 100px;
  height: 100px;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #FEE9DE;
  color: #FF614D;
  line-height: 0px;
}

.blogger-support .support-action-btn .action-btn-desc {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.blogger-support .faquestion>div>section>section {
  border-bottom: none !important;
  border-radius: 10px;
  box-shadow: 0px 5px 40px 0px rgba(146, 196, 255, 0.20);
  margin: 10px 0;
  padding-left: 24px !important;
}

.blogger-support .faquestion>div>section>section span.icon-wrapper {
  background-color: rgba(250, 247, 245, 1);
  border-radius: 50%;
  display: inline-block;
}

@media (min-width: 750px) {
  .blogger-support {
    display: flex;
  }

  .blogger-support .mobile-menu {
    display: none;
  }

  .blogger-support .blogger-support-main {
    width: 100%;
  }

  .blogger-support .blogger-support-main .body {
    padding: 30px 60px;
  }
}