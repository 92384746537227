.campaign-setup {
  background-color: #FAFAFA;
  min-height: 100vh;
}

.campaign-setup .campaign-setup-body {
  padding: 100px 20px;
  display: grid;
  /* gap: 23px; */
}

.campaign-setup .main-form-content {
  background-color: #fff;
  padding: 40px 20px;
  font-family: "DM Sans", sans-serif;
  margin-top: 31px;
  container-name: textarea;
  container-type: inline-size;
}

.campaign-setup .text-input,
.campaign-setup .select {
  border-radius: 4px;
  border: 0.7px solid #D4D4D4;
  background: #FCFCFC;
  padding: 20px 16px;
  color: #9D9A9A;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  outline: none;
  transition: border ease 100ms;

  &:focus {
    border: 2px solid #D4D4D4;
  }
}

.campaign-setup .label {
  color: #2E302F;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  cursor: pointer;
}

.campaign-setup .heading {
  color: #0A0F0D;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
}

.campaign-setup .checkbox {
  width: 24px;
  height: 24px;
  color: #FF614D;
  vertical-align: middle;
  -webkit-appearance: none;
  background-color: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  background-color: #FFFFFF;
  transition: background-color 300ms;
  cursor: pointer;
  /* transform: translateY(7px); */
}

.campaign-setup .select {
  padding: 16px;
  color: #9D9A9A;
  outline: none;
  border-radius: 4px;
  border: 0.7px solid #D4D4D4;
  background: #FCFCFC;
}


/* Pseudo element for check styling */

.campaign-setup .checkbox::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 3px #FF614D;
  border-radius: 3px;
}


/* Checked */

.campaign-setup .checkbox:checked {
  background-color: currentcolor;
}

.campaign-setup .checkbox:checked::before {
  content: "\2713";
  box-shadow: none;
  display: grid;
  justify-content: center;
  align-items: center;
  color: #fff;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E"); */
}


/* Disabled */

.campaign-setup .checkbox:disabled {
  background-color: #FF614D;
  opacity: 0.84;
  cursor: not-allowed;
}

.campaign-setup .influencer-level {
  color: #222;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 32px;

  & .head-text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  & .label-text {
    color: #717171;
    font-size: 12px;
    font-weight: 400;
  }
}

.campaign-setup .age-group {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;

  & .head-text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  & .options-row {
    color: #2E302F;
    font-size: 16px;
  }
}

.campaign-setup .options-row {
  margin-bottom: 16px;
}

.campaign-setup .publish-date {
  color: #222;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 30px;

  & .select-publish-date-input {
    display: block;
    width: 100%;
    max-width: 207px;
    border-radius: 8px;
    outline: none;
    border: 2px solid #FF614D;
    padding: 16px;
    text-align: center;
    margin-top: 16px;
    color: #FF614D;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
  }

  & .select-publish-date-input::placeholder {
    color: #FF614D;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
  }
}

.campaign-setup .select-container {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  margin-top: 30px;

  & .select-row {
    margin-bottom: 30px;
    display: grid;
    gap: 8px;
  }

  & .select {
    border-radius: 4px;
    border: 0.7px solid #D4D4D4;
    background: #FCFCFC;
    padding: 16px;
    display: block;
    width: 100%;
    max-width: 400px;
    color: #9D9A9A;
  }
}

.campaign-setup .platform-preference {
  display: grid;
  gap: 29px;
  margin-top: 30px;
  font-weight: 400;
  line-height: 140%;
  font-family: "DM Sans", sans-serif;

  & .head-text {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }

  & .platform-preference-row {
    gap: 29px;
    flex-wrap: wrap;
  }

  & .platform-desc {
    user-select: none;
  }
}

.campaign-setup .platform-preference-capsule {
  border-radius: 35px;
  background-color: #FCFBFB;
  padding: 16px;
  display: grid;
  place-content: center;
  max-width: 212px;
  width: 100%;
  color: #2E302F;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.campaign-setup .platform-preference-checkbox {
  width: 24px;
  height: 24px;
  /* color: #FF614D; */
  color: transparent;
  vertical-align: middle;
  -webkit-appearance: none;
  background-color: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  /* background-color: #FFFFFF; */
  background-color: transparent;
  transition: background-color 300ms;
  cursor: pointer;
  /* transform: translateY(7px); */
}

/* Pseudo element for check styling */

.campaign-setup .platform-preference-checkbox::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  /* box-shadow: inset 0 0 0 3px #FF614D; */
  box-shadow: inset 0 0 0 3px transparent;
  border-radius: 3px;
}

/* Checked */

.campaign-setup .platform-preference-checkbox:checked {
  background-color: currentcolor;
}

.campaign-setup .platform-preference-checkbox:checked::before {
  content: "\2713";
  box-shadow: none;
  display: grid;
  justify-content: center;
  align-items: center;
  /* color: #fff; */
  color: #000;
  font-size: 20px;
  font-weight: bold;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E"); */
}


/* Disabled */

.campaign-setup .platform-preference-checkbox:disabled {
  background-color: #FF614D;
  opacity: 0.84;
  cursor: not-allowed;
}

.campaign-setup .budget {
  margin: 40px 0 32px;

  & .head-text {
    color: #000;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }

  & .amount-input-container {
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    padding: 16px;
    max-width: 403px;
    margin: 8px 0 32px;
  }

  & .currency-btn {
    border-right: 1px solid #2E302F;
    color: #2E302F;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  & .budget-amount-input {
    background-color: transparent;
    color: #2E302F;
    border: none;
    outline: none;
  }
}

.campaign-setup .budget-button-container {
  flex-wrap: wrap;

  & .budget-btn {
    border-radius: 20px;
    border: 1px solid #5A5A5A;
    background-color: #FAFAFA;
    padding: 12px 24px;
    width: 100%;
    max-width: 206px;
    text-align: left;
    justify-content: space-between;
  }
}

@media (min-width: 750px) {
  .campaign-setup .campaign-setup-body {
    padding: 100px 60px;

    display: flex;
    gap: 23px;

    &>*:last-child {
      flex: 1;
    }
  }
}