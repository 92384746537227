.blogger-payment {
  min-height: 100vh;
  background-color: #FAFAFA;
}

.blogger-payment .blogger-payment-main .body {
  padding: 18px;
}

@media (min-width: 750px) {
  .blogger-payment {
    display: flex;
  }

  .blogger-payment .mobile-menu {
    display: none;
  }

  .blogger-payment .blogger-payment-main {
    width: 100%;
  }

  .blogger-payment .blogger-payment-main .body {
    padding: 18px 60px;
  }
}