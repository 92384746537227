.added-platform-card {
  background-color: #FAFAFA;
  padding: 24px;
  color: #000;
  font-weight: 400;
}

.added-platform-card .card-content-container {
  max-width: 300px;
}

.added-platform-card .remove-platform-btn {
  color: #FF614D;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  padding: 24px;
}

@media (max-width: 750px) {
  .added-platform-card .container {
    flex-direction: column;
  }
}