.website-sidebar{
    background-color: #212322;
    width: 40%;
    min-height: 100%;
}

@media only screen and (max-width: 750px) {
  .website-sidebar{
    display: none;
  }
}

.logo-img{
    width: max-content;
    margin: 3em auto;
    cursor: pointer;
}

.sidebar-content{
    margin: 5em auto 3em auto;
    width: 80%;
}

.sidebar-content .selfie-img{
    width: 90%;
    margin: 2em auto;
}

.sidebar-content img{
    width: max(300px, 90%);
    display: block;
    margin: auto;
}

.sidebar-content p{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 90%;
    margin: 1em auto 2em auto;
}