.activities-preview{
    margin: 0.5em 0;
  }
  
.activity-details{
    display: flex;
    gap: 1em;
    margin-bottom: 0.5em;
  }
.activity-details h4{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 2px;
  }
  
.activity-details p{
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 2px;
  }
  
.activity-details p span{
    font-size: 12px;
    display: flex;
    align-items: center;
  }