.about-page nav {
  background-color: white;
}

.about-header-container h2,
.about-team-container h2,
.about-cta-container h2 {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  color: #040404;
  font-weight: 700;
  line-height: 96%;
}

.about-header-container,
.about-team-container,
.about-cta-container {
  position: relative;
  padding: 16px;
  text-align: center;
}



.about-team-container p,
.about-cta-container p {
  font-size: 22px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  line-height: 31px;
  margin: 20px 0;
}

.about-cta-container a {
  background-color: #ff614d;
  color: white;
  font-family: "Dm Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  padding: 16px 30px;
  border-radius: 50px;
}

.about-team-section-container {
  background-color: white;
  margin: 100px 0;
}

.about-lightbg-section .about-bg-container,
.about-darkbg-section .about-bg-container {
  padding: 90px 20px;
  display: flex;
  flex-direction: column;
  gap: 87px;
}

.about-bg-container>* {
  flex: 1;
}

.about-lightbg-section {
  background-color: #E5E5E5;
}

.about-darkbg-section {
  background-color: #0A0F0D;
}

.about-bg-article {
  font-weight: 600;
  font-family: "DM Sans", sans-serif;

}

.about-bg-article h2 {
  color: #FF614D;
  font-size: 30px;

}

.about-bg-article p {
  font-size: 15px;
  color: #040404;
}

.about-darkbg-section .about-bg-article p {
  color: #fff;
}

.about-bg-article p:first-of-type {
  margin: 30px 0;
}

@media (min-width: 750px) {
  .about-page nav {
    background-color: #030303;
  }

  .about-header-container {
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-header-container h2,
  .about-team-container h2,
  .about-cta-container h2 {
    color: white;
    font-size: 76px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
  }

  .about-team-container p,
  .about-cta-container p {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .about-header-easier-span {
    color: #ff614d;
  }

  .about-header-mobile-image {
    display: none;
  }

  .about-team-container,
  .about-cta-container {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }

  .about-team-section-container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }

  .about-team-section-container>* {
    flex: 1;
  }

  .about-lightbg-section,
  .about-darkbg-section {
    padding: 90px 0;
  }

  .about-lightbg-section .about-bg-container,
  .about-darkbg-section .about-bg-container {
    width: 75%;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }

  .about-bg-article h2 {
    color: #FF614D;
    font-size: 40px;

  }

  .about-bg-article p {
    font-size: 20px;
    color: #040404;
  }
}

@media (max-width: 750px) {
  .about-header-mobile-image {
    margin-top: 30px;
  }

  .about-cta-container {
    margin-bottom: 50px;
  }


  .about-team-section-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

  }
}