.notification-type{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification-type h4{
    font-size: 16px;
    font-weight: 700;
}

.notification-type p{
    color: #C1BFBF;
    font-size: 32px;
}