.complete-signup-page{
    background-color: #FAFAFA;
}

/* First screen, user select curator type */
.select-curator-type{
    width: 80%;
    margin: 12em auto;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 32px 48px;
}

.select-curator-type h4{
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.select-curator-type .curator-options{
    display: flex;
    justify-content: space-between;
    margin: 32px 0 15em 0;
}

.select-curator-type .curator-options span,
.select-curator-type .continue-previous-btns button{
    border-radius: 8px;
    border: 2px solid #D9D9D9;
    width: min(250px, 20%);
    text-align: center;
    padding: 32px 0;
    color: #FF614D;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.select-curator-type .continue-previous-btns{
    display: flex;
    justify-content: flex-end;
    gap: 2em;
    margin-bottom: 2em;
}

.select-curator-type .continue-previous-btns button{
    background-color: #fff;
}

.select-curator-type .curator-options .selected-curator,
.select-curator-type .continue-previous-btns  .selected-option{
    border: 2px solid #FF614D;
    color: #fff;
    background-color: #FF614D;
}