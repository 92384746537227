.bg-img-grid-container {
  position: absolute;
  z-index: -3;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(3, 33.33%);
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-auto-flow: dense;
}

.bg-img-grid-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.bg-img-overlay {
  position: absolute;
  /* background-color: rgba(255, 98, 77, 0.3); */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 97, 77, 0.30);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.bg-img-row-span3 {
  grid-row: 1 / -1;
}

.bg-img-start3-col-span2 {
  grid-column: 3 / 5;
  grid-row: 1 / span 2;
}

.bg-img-start2-col-span2 {
  grid-column: 2 / 4;
  grid-row-start: 3;
}

.six {
  grid-row-start: 2;
  grid-column-start: 2;
}

.five {
  grid-column: 4 / 5;
  grid-row-start: 3;
}

/* .two {
  grid-row-start: 1;
} */

@media (max-width: 750px) {

  .bg-img-grid-container,
  .bg-img-overlay {
    display: none;
  }
}