.button-footer {
  background-color: #fff;
  padding: 10px;
  margin-top: 50px;
}

.button-footer .button {
  padding: 24px 12px;
  border-radius: 8px;
  background: #FF614D;
  color: #FFF;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.button-footer .prev-step-btn {
  font-size: 16px;
  color: #000;
  background-color: transparent;
}