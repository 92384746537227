.homenav {
  background-color: white;
}

.homenav span {
  cursor: pointer;
}

.homenav-container {
  width: 85%;
  padding: 25px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
}

.homenav-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homenav ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #2E302F;
  font-family: DM Sans;
  font-weight: 500;
}

.homenav li a {
  display: block;
}

.homenav li a:hover {
  text-decoration: underline;
}

.homenav-btn-links {
  display: flex;
  align-items: center;
  gap: 24px;
}

.homenav-btn {
  /* #F0E8E7 */
  border-radius: 25px;
  padding: 15px 30px;
  display: inline-block;
}

.homenav-login-btn {
  background-color: #F0E8E7;
  color: #FF614D;
}

.homenav-signup-btn {
  color: white;
  background-color: #FF614D;
}

@media (min-width: 750px) {
  .homenav {
    background-color: transparent;
  }

  .homenav-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .toggle-burger {
    display: none;
  }

  .homenav ul {
    color: white;
    flex-direction: row;
    gap: 15px;
  }

  .homenav-login-btn {
    color: white;
    background-color: transparent;
    border: 2px solid white;
    box-sizing: border-box;
  }
}

@media (max-width: 1180px) and (min-width: 750px) {
  .homenav-container {
    justify-content: center;
  }
}

.done {
  color: white;
}