.about-team-card-container {
  color: white;
  font-family: "Poppins", sans-serif;

  text-align: center;
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  align-items: center;
  /* flex: 1 0 0; */
  border-radius: 25px;
  border: 3px solid #2E302F;
  background-color: #442927;
}

.about-team-card-container .team-member-name {
  line-height: normal;
  font-size: 36px;
  font-weight: 400;
}

.about-team-card-container .team-member-post {
  line-height: normal;
  font-size: 24px;
  font-weight: 400;
}

@media (max-width: 750px) {
  .about-team-card-container .team-member-name {

    font-size: 26px;

  }

  .about-team-card-container .team-member-post {

    font-size: 14px;

  }
}