.add-music-content .select-content-type-container {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
  margin-left: auto;
  position: relative;
}

.add-music-content .select-content-type-container .content-type-btn {
  font-family: inherit;
  padding: 12px 16px;
  width: 150px;
  border-radius: 8px;
  border: 1px solid #000;
}

.select-content-type-container .content-type-dropdown{
  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  background: #ebebeb;
  border-radius: 8px;
  z-index: 1;
  right: 0;
  top: 100%;
  box-shadow: 1px 2px 4px #eee;
}

.select-content-type-container .show-content-types{
  display: block;
}

.select-content-type-container .content-type-dropdown li{
  padding: 10px;
}

.select-content-type-container .content-type-dropdown li:hover {
  background-color: #FF614D;
  color: white;
  cursor: pointer;
  border-radius: 8px;
}

.add-music-content .upload-form {
  padding: 20px;
  background-color: white;
  margin: 20px 0 80px 0;
}

.add-music-content .upload-form label {
  cursor: pointer;
  color: #434144;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
}

.add-music-content .upload-form input:not(input[type="file"]):not(input[type="checkbox"]) {
  border-radius: 4px;
  border: 1px solid #D4D4D4;
  outline: none;
  background-color: #FCFCFC;
  padding: 20px 16px;
  width: 100%;
  transition: all ease .1s;
}

.add-music-content input:focus,
.add-music-content .text-area:focus {
  border: 2px solid #FF614D !important;
}

.add-music-content .upload-form input:focus:not(input[type="checkbox"]) {
  border: 2px solid #FF614D;
}

.add-music-content .upload-form .button-upload, 
.add-music-content .upload-form .button-not-upload {
  color: white;
  background-color: #FF614D;
  padding: 16px 20px;
  border-radius: 8px;
  width: fit-content;
  width: 210px;
  font-size: 16px;
}

.add-music-content .upload-form .button-not-upload{
  color: black;
  background-color: transparent;
  border: 1px solid black;
}

.add-music-content .upload-form .button-upload:focus {
  border: 2px solid #000;
}

.add-music-content .upload-form .allow-download-row {
  margin: 20px 0;
  padding: 10px 0;
}

.add-music-content .upload-form .text-area {
  padding: 20px 16px;
  border-radius: 4px;
  border: 1px solid #D4D4D4;
  background: #FCFCFC;
  resize: none;
  height: 242px;
  outline: none;
}

.add-music-content .upload-form .select-date {
  border: 2px solid #FF614D !important;
  outline: none;
  color: #FF614D;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.add-music-content .upload-form .select-date[type="text"]:placeholder-shown {
  /* width: 20px;
  height: 20px; */
  background-image: url("../../../../assets/images/calendar_month.png");
  background-position: 20px center;
  background-repeat: no-repeat;
}

.add-music-content .upload-form .select-date::placeholder {
  color: #FF614D;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  border-radius: 8px;
  padding-left: 30px;
}