.influencer-campaign-header {
  background-color: white;
  padding: 24px;
}

.influencer-campaign-header .blogger-profile {
  margin-left: auto;
  flex-wrap: wrap;
  justify-content: center;
}

.influencer-campaign-header .blogger-profile .header-btn-container {
  color: #9D9A9A;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
}

.influencer-campaign-header .blogger-profile .header-btn-container .header-btn.active {
  color: #FF614D;
}


.influencer-campaign-header .blogger-avatar {
  width: 60px;
  height: 60px;
  background-color: #FF614D;
  color: #FFF;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  justify-content: center;
  align-items: center;
}

.influencer-campaign-header .heading {
  color: #2E302F;
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}

@media(max-width: 750px) {

  .influencer-campaign-header .heading,
  .influencer-campaign-header .logo {
    display: none;
  }
}

@media (min-width: 750px) {
  .influencer-campaign-header {
    padding: 24px 60px;
  }
}