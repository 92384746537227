.create-influencer-campaign .create-influencer-campaign-body {
  background-color: #FAFAFA;
  padding: 24px;
}

@media (min-width: 750px) {
  .create-influencer-campaign .create-influencer-campaign-body {
    padding: 100px;
  }

  .create-influencer-campaign .footer-next-btn-container {
    padding: 24px 100px;
  }

}

.create-influencer-campaign .footer-next-btn-container {
  background-color: #E2E2E2;
  padding: 24px;
  display: flex;
  flex-direction: row-reverse;
}

.create-influencer-campaign .footer-next-btn-container .next-btn {
  padding: 24px 35px;
  border-radius: 8px;
  background: #FF614D;
  color: #FFF;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-left: auto;
}