.blogger-dashboard-content {
    background-color: #FAFAFA;
    min-height: 100vh;
}

.blogger-dashboard-content .blogger-dashboard-content-main .body {
    padding: 18px;
}

.blogger-dashboard-content button.show {
    display: inline-block;
}

.blogger-dashboard-content button.hide {
    display: none;
}

.blogger-dashboard-content .blogger-dashboard-content-header {
    background-color: white;
    padding: 20px;
}
.blogger-dashboard-content .blogger-dashboard-content-header .main-navbar-content {
    max-width: 650px;
    gap: 50px;
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.blogger-dashboard-content .blogger-dashboard-content-header .header-btn {
    color: #9D9A9A;
    padding: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
}
.blogger-dashboard-content .blogger-dashboard-content-header .header-btn.active {
    color: #FF614D;
}

.blogger-dashboard-content .avatar {
    color: #FFF;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    border-radius: 50%;
    background: #FF614D;
    width: 58px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* if it should be an image, then background image */
}

@media (min-width: 750px) {
    .blogger-dashboard-content {
      display: flex;
    }

    .blogger-dashboard-content .mobile-menu {
        display: none;
    }
  
    .blogger-dashboard-content .blogger-dashboard-content-main {
      width: 100%;
    }
  
    .blogger-dashboard-content .blogger-dashboard-content-main .body {
      padding: 18px 60px;
    }

    .blogger-dashboard-content .blogger-dashboard-content-header .main-navbar-content {
        flex-direction: row;
    }

    .blogger-dashboard-content .blogger-dashboard-content-header {
        padding-right: 60px;
    }
}