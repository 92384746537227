.withdraw-fund .heading {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
}

.withdraw-fund .withdraw-fund-body {
  font-family: "DM Sans", sans-serif;
  padding: 24px;
  margin: 122px auto;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  max-width: 700px;
}

@media (min-width: 900px) {
  .withdraw-fund .withdraw-fund-body {
    padding: 50px;
  }
}

.withdraw-fund .text {
  font-size: 16px;
  font-weight: 500;
}

.withdraw-fund .select-amount-container {
  margin: 48px auto 20px;
  color: #2E302F;
}

.withdraw-fund .select-amount,
.withdraw-fund .input-amount {
  padding: 16px;
  border-radius: 4px;
  border: 0.7px solid #D4D4D4;
  background-color: #FCFCFC;
  color: #9D9A9A;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  width: 100%;
}

.withdraw-fund .amount-btn-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 30px;
}

.withdraw-fund .button-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  /* flex-wrap: wrap; */
  justify-content: center;
}

.withdraw-fund .amount-btn {
  border-radius: 8px;
  border: 2px solid #5A5A5A;
  padding: 24px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.withdraw-fund .add-fund-btn {
  border-radius: 8px;
  background-color: #FF614D;
  padding: 24px 12px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  margin-top: 40px;
}