.campaign {
  background-color: #fafafa;
  min-height: 100vh;
}

.campaign .campaign-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.campaign .campaign-main .campaign-body {
  padding: 18px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.campaign .campaign-main .nocampaign-card {
  padding: 45px;
  background-color: white;
  text-align: center;
  max-width: 750px;
  font-family: DM Sans;
  line-height: 140%;
  font-weight: 500;
}

.campaign .campaign-main .nocampaign-card .desc-heading {
  color: #000;
  font-size: 24px;
  margin: 10px auto;
}

.campaign .campaign-main .nocampaign-card .suggestion-text {
  color: #5a5a5a;
  font-size: 20px;
  margin-bottom: 32px;
}

.campaign .campaign-main .nocampaign-card .start-campaign-btn {
  border-radius: 8px;
  background: #ff614d;
  padding: 20px 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.campaign .campaign-main .campaign-list-body .search-filter-campaign-section {
  background-color: #fff;
  margin: 3em 0 7em 0;
}

.campaign
  .campaign-main
  .campaign-list-body
  .search-filter-campaign-section
  .search-filter-campaign-section-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.campaign
  .campaign-main
  .campaign-list-body
  .search-filter-campaign-section
  .search-filter-campaign-section-container
  form {
  width: 40%;
  position: relative;
}

.campaign
  .campaign-main
  .campaign-list-body
  .search-filter-campaign-section
  .search-filter-campaign-section-container
  form
  span {
  position: absolute;
  left: 10px;
  top: 30%;
  font-size: 20px;
}

.campaign
  .campaign-main
  .campaign-list-body
  .search-filter-campaign-section
  .search-filter-campaign-section-container
  form
  input {
  width: 100%;
  padding: 16px 32px;
  border-radius: 20px;
  background-color: #fafafa;
  font-size: 14px;
  color: #000;
  border: 0;
}

.campaign
  .campaign-main
  .campaign-list-body
  .search-filter-campaign-section
  .search-filter-campaign-section-container
  .filter-campaign
  button {
  background-color: transparent;
  border: 1px solid #ff614d;
  border-radius: 8px;
  padding: 14px 24px;
  color: #ff614d;
  font-size: 14px;
}

.campaign
  .campaign-main
  .campaign-list-body
  .search-filter-campaign-section
  .search-filter-campaign-section-container
  .create-new-campaign
  button {
  background-color: #ff614d;
  border: 1px solid #ff614d;
  border-radius: 8px;
  padding: 14px 20px;
  color: white;
  font-size: 14px;
}

.campaign .campaign-main .campaign-list-body .campaign-table {
  width: 90%;
  margin: 0 auto;
}

.campaign .campaign-main .campaign-list-body .campaign-table table {
  width: 100%;
  border-collapse: collapse;
}

.campaign .campaign-main .campaign-list-body .campaign-table table th {
  background-color: #fff;
  font-weight: 900;
  font-family: Montserrat;
}

.campaign .campaign-main .campaign-list-body .campaign-table table th,
.campaign .campaign-main .campaign-list-body .campaign-table table td {
  padding: 16px 0;
}

.campaign .campaign-main .campaign-list-body .campaign-table table td {
  text-align: center;
}

.campaign .campaign-main .campaign-list-body .campaign-table table tr.even {
  background-color: #f9f9f9;
}

.campaign .campaign-main .campaign-list-body .campaign-table table tr.odd {
  background-color: #fff;
}

/* TODO => Status colors -> .draft | .under_review | .published | .declined */

.campaign
  .campaign-main
  .campaign-list-body
  .campaign-table
  table
  td
  .published {
  color: #1f9254;
  background-color: #ebf9f1;
  border-radius: 20px;
  padding: 8px 12px;
}

.campaign .campaign-main .campaign-list-body .campaign-table table td .draft {
  color: #ff0000;
  background-color: rgba(255, 0, 0, 0.04);
  border-radius: 20px;
  padding: 8px 12px;
}

.campaign .campaign-main .campaign-list-body .campaign-table table td .action {
  color: #ff614d;
  background-color: transparent;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid #ff614d;
}

@media (min-width: 750px) {
  .campaign {
    display: flex;
  }

  .campaign .campaign-main {
    width: 100%;
  }

  .campaign .campaign-main .campaign-body {
    padding: 18px 60px;
  }
}
