.login-page{
    display: flex;
}

.login-section{
    width: 90%;
    min-height: 100vh;
}

.login-section-container{
    width:80%;
    margin: 10em auto;
    color: #18181B;
}

.login-section-container h3{
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
}

.login-section-container p{
    font-size: 15px;
    font-weight: 400;
    line-height: 26px; 
}

.login-section-container form{
    margin-top: 4em;
}

.login-section-container form .form-field{
    width: 80%;
    margin: 10px 0;
}

.login-section-container form .form-field label{
    display: inline-block;
    margin-bottom: 10px;
}

.login-section-container form .form-field input{
    width: 100%;
    padding: 20px 16px;
    font-size: 16px;
    border-radius: 5px;
    color: black;
    background-color: #fcfcfc;
    border: 1px solid #d4d4d4;
}

.login-section-container form .form-field input::placeholder{
    color: #aaa;
}

.login-section-container form .form-field small{
    color: #DA3D2A;
}

.login-section-container .forgot-password{
    display: block;
    color: #FF614D;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-decoration: none;
    margin: 24px 0 48px 0;
}

.login-section-container form .button-submit{
    margin-top: 2.5em;
}

.login-section-container form .button-submit input{
    background-color: #FF614D;
    color: #fff;
    border: 1px solid #FF614D;
    cursor: pointer;
}

.login-section-container form .blur input{
    opacity: 0.5;
}

.login-section-container .dont-have-acct{
    width: 80%;
    text-align: center;
    margin-top: 1.4em;
    font-size: 16px;
    font-weight: 500;
}

.login-section-container .dont-have-acct .link{
    color: #FF614D;
    text-decoration: none;
}