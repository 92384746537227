.complete-signup-page{
    background-color: #FAFAFA;
}

/* First screen, user select curator type */
.select-curator-type{
    width: 80%;
    margin: 12em auto;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 32px 48px;
}

.select-curator-type h4{
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.select-curator-type .curator-options{
    display: flex;
    justify-content: space-between;
    margin: 32px 0 15em 0;
}

.select-curator-type .curator-options span,
.select-curator-type .continue-previous-btns button{
    border-radius: 8px;
    border: 2px solid #D9D9D9;
    width: min(250px, 20%);
    text-align: center;
    padding: 32px 0;
    color: #FF614D;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.select-curator-type .continue-previous-btns{
    display: flex;
    justify-content: flex-end;
    gap: 2em;
    margin-bottom: 2em;
}

.select-curator-type .continue-previous-btns button{
    background-color: #fff;
}

.select-curator-type .curator-options .selected-curator,
.select-curator-type .continue-previous-btns  .selected-option{
    border: 2px solid #FF614D;
    color: #fff;
    background-color: #FF614D;
}

/* User details collection section 1 */

.blogger-onboard-details {
    width: 90%;
    margin: 5em auto 15em auto;
}
.blogger-onboard-details .progress-bar,
.blogger-onboard-details .blogger-input-form-section-1{
    width: max(500px, 40%);
    margin: 0 auto 5em auto;
}

.blogger-onboard-details .progress-bar{
    display: flex;
    align-items: center;
    justify-content: center;
}

.blogger-onboard-details .progress-bar .progess-point-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
}

.blogger-onboard-details .progress-bar{
    color: #FF614D;
}

.blogger-onboard-details .progress-bar .progress-line{
    width: 250px;
    height: 3px;
    background-color: #FF614D;
}

.blogger-onboard-details .progress-bar .change-bg{
    width: 250px;
    height: 3px;
    background-color: transparent;
}

.blogger-onboard-details .unchecked-progress-point{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #FF614D;
}

.blogger-onboard-details .back-icon{
    color: #FF614D;
    font-size: 24px;
    cursor: pointer;
}

.blogger-onboard-details .blogger-input-form-section-1 h4{
    margin: 20px 0 32px 0;
    font-size: 20px;
    color: #18181B;
}

.blogger-onboard-details .reduce-space h4{
    margin: 20px 0 10px 0 !important;
    text-align: center;
}

.blogger-onboard-details .blogger-input-form-section-1 p{
    text-align: center;
    margin-bottom: 24px;
}

.blogger-onboard-details .blogger-input-form-section-1 form{
    width: 90%;
    margin: 0 auto;
}

.blogger-onboard-details .reduce-space form{
    width: 70%;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field{
    width: 100%;
    margin: 10px 0;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field label{
    display: inline-block;
    margin-bottom: 10px;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field input,
.blogger-onboard-details .blogger-input-form-section-1 form .form-field select,
.blogger-onboard-details .blogger-input-form-section-1 form .form-field .upload{
    width: 100%;
    padding: 20px 16px;
    font-size: 16px;
    border-radius: 5px;
    color: black;
    background-color: #fcfcfc;
    border: 1px solid #d4d4d4;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field input::placeholder,
.blogger-onboard-details .blogger-input-form-section-1 form .form-field select::placeholder,
.blogger-onboard-details .blogger-input-form-section-1 form .form-field .upload::placeholder{
    color: #aaa;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field small{
    color: red;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field input[type='file']{
    display: none;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field .upload{
    text-align: center;
    color: #FF614D;
    background-color: #FCE4D8;
    display: flex;
    gap: 10px;
    justify-content: center;
    cursor: pointer;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field input[type='button'],
.blogger-onboard-details .blogger-input-form-section-1 form .form-field input[type='submit'],
.blogger-onboard-details .blogger-input-form-section-1 form .form-field button{
    background-color: #FF614D;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
}

.blogger-onboard-details .blogger-input-form-section-1 form .form-field button{
    width: 100%;
    border-radius: 5px;
}