.musician-confirmation-page{
    display: flex;
    position: relative;
}

.login-section{
    width: 90%;
    min-height: 100vh;
}

.login-section-container{
    width:80%;
    margin: 10em auto;
    color: #18181B;
}

.signup-success-container{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
}


.notification-top{
    display: none;
    align-items: center;
    position: absolute;
    right: 0;
    top: 1%;
    padding: 24px 30px;
    border: 1px solid #eee;
    border-left: 5px solid #C1F1CD;
    border-radius: 5px;
    gap: 3em;
}

.show-notification{
    display: flex;
}

.confirmation-icon-text{
    display: flex;
    align-items: center;
    gap: 16px;
}

.confirmation-icon-text h4{
    color: #30D158;
}

.confirmation-icon-text p{
    color: #5B585B;
    font-size: 13px;
    margin-top: 12px;
}

.signup-success-container .link-resend{
    color: blue !important;
    cursor: pointer;
}

.notification-top .close-btn{
    color: #5B585B;
    font-size: 16px;
    cursor: pointer;
    font-weight: 700;
}

.success-icon{
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #30D158;
    border: 1px solid #D6F6DE;
    border-radius: 50%;
    background-color: #D6F6DE;
    
}

.success-inner-circle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid #30D158;
    border-radius: 50%;
    font-size: 14px;
}