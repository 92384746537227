.account-setting {
    background-color: #FAFAFA;
    min-height: 100vh;
  }
.account-setting-body{
    width: max(70%, 600px);
    margin-left: 4em;
}
.account-setting-selector{
    width: 90%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F0ECEC;
    padding-right: 2em;
    margin: 3em 0;
}

.account-setting-selector h5{
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.account-setting-selector h5.active-info{
    border-bottom: 2px solid #FF614D;
}

.account-setting-edit-details{
    background-color: #fff;
    width: 100%;
    padding: 3em 2em;
}

.account-setting-edit-details h3{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1em;
}

.account-setting-edit-details .form-line{
    width: 100%;
    height: 1px;
    background-color: #F0ECEC;
    margin-bottom: 1.5em;
}

@media (min-width: 750px) {
    .account-setting {
      display: flex;
    }
  
    .account-setting .account-setting-main {
      width: 100%;
    }
}


