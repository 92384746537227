.blogger-welcome {
  background-color: #FAFAFA;
  min-height: 100vh;
}

.blogger-welcome .blogger-welcome-main .body {
  padding: 18px;
}

.blogger-welcome .blogger-welcome-main .welcome-card {
  border-radius: 8px;
  border: 1px solid #E0DADA;
  background: #FFF;
  padding: 24px;
  text-align: center;
  margin-top: 50px;
  color: #2E302F;
}

.blogger-welcome .blogger-welcome-main .welcome-card .greeting {
  font-size: 24px;
  font-weight: 600;
  line-height: 140%;
  margin: 10px 20px;
}

.blogger-welcome .blogger-welcome-main .welcome-card .text {
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
}

.blogger-welcome .blogger-welcome-main .welcome-card .add-payout-account-btn {
  padding: 14px 35px;
  border-radius: 8px;
  background: #FF614D;
  color: white;
  margin-top: 30px;
}

@media (min-width: 750px) {
  .blogger-welcome {
    display: flex;
  }

  .blogger-welcome .blogger-welcome-main {
    width: 100%;
  }

  .blogger-welcome .blogger-welcome-main .body {
    padding: 18px 100px;
  }
}