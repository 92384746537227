.mail-verification-warning{
    width: 80%;
    margin: 20px auto;
    border-left: 2px solid red;
}

.mail-verification-warning p{
    font-size: 15px;
    font-weight: 500;
    background-color: white;
    text-align: center;
    padding: 10px 0;
}

.mail-verification-warning p .verification-mail-link{
    text-decoration: underline;
    color: coral;
}