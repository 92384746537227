.content-to-share .heading {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 45px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  margin: 20px auto 50px;
}

.content-to-share .back-arrow-btn {
  color: #FF614D;
  font-size: 40px;
}

.content-to-share .options-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  /* flex-direction: column; */
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 750px) {
  .content-to-share .options-container {
    flex-wrap: wrap;
    flex-direction: column;
  }

}

.content-to-share .label-container {
  /* width: fit-content; */
  position: relative;
}

.content-to-share .hidden-input {
  position: absolute;
  visibility: hidden;
}

.content-to-share .hidden-input:checked+.nocampaign-card {
  border: 1px solid #FF614D;
}

.content-to-share .nocampaign-card .checkmark-icon-container {
  width: 24px;
  height: 24px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  border: 1px solid #FF614D;
}

.content-to-share .nocampaign-card .checkmark {
  opacity: 0;
  color: #FF614D;
  font-size: 26px;
  transform: translate(-2px, -2px);
  transition: all ease .2s;
}

.content-to-share .hidden-input:checked+.nocampaign-card .checkmark-icon-container {
  border: none;
}

.content-to-share .hidden-input:checked+.nocampaign-card .checkmark {
  opacity: 1;
}

.content-to-share .nocampaign-card {
  padding: 24px;
  background-color: white;
  font-family: "DM Sans", sans-serif;
  line-height: 140%;
  font-weight: 500;
  /* display: block; */
  border: 1px solid #2E302F;
  cursor: pointer;
  user-select: none;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content-to-share .nocampaign-card:focus {
  border: 3px solid black;
}

.content-to-share .nocampaign-card .desc-heading {
  color: #000;
  font-size: 24px;
  margin: 10px auto;
}