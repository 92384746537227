.bloger-welcome{
    background-color: #fafafa;
}
.blogger-welcome-screen{
    width: max(50%,500px);
    margin: 5em auto;
}

.blogger-welcome-screen img{
    display: block;
    margin: 0 auto;
}

.blogger-welcome-screen h3{
    text-align: center;
    margin: 2em 0 2em 0;
    font-size: 22px;
    font-weight: 500;
}

.blogger-welcome-screen p {
    font-size: 16px;
    text-align: center;
}

.blogger-welcome-screen button{
    padding: 22px;
    width: 60%;
    display: block;
    background-color: #FF614D;
    border: 1px solid #FF614D;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    margin: 5em auto 10em auto;
    cursor: pointer;
}