@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.content-request-card {
    background-color: white;
    border-radius: 8px;
    gap: 30px;
    padding: 24px;
    border: 1px solid var(--new-color-secondary, #F0E8E7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
}


.content-request-card .content-type-container {
    display: flex;
    gap: 24px;
    align-items: center;
    color: #000;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
}

.content-request-card .content-type-container .img {
    border-radius: 8px;
    overflow: hidden;
}

.content-request-card .button {
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    padding: 12px 30px;
    border-radius: 8px;
}

.content-request-card .content-type.button {
    background-color: #F0E8E7;
}

.content-request-card .content-status.button {
    background-color: #F6CBC5;
}

.content-request-card .content-status.under-review-btn {
    background-color: #6BCD9D;
}

.content-request-card .button-container {
    gap: 50px;
}

.content-request-card .accept-btn {
    color: #fff;
    background-color: #FF614D;
}

.content-request-card .accept-btn:disabled {
    color: #fff;
    background-color: #CCACA8;
    cursor: auto;
}

.content-request-card .cancel-btn {
    background-color: #fff;
    color: #FF614D;
    border: 2px solid #FF614D;
}

.content-request-card .decline-btn {
    color: #FF614D;
    background-color: #F9F6F6;
}

.content-request-card .decline-btn:disabled {
    color: #FF614D;
    background-color: #F9F6F6;
    cursor: auto;
}

.content-request-card .submit-post-link-btn {
    color: #fff;
    background-color: #FF614D;
}

.content-request-card .submit-post-link-btn.submitted {
    background-color: #2E302F;
}

.content-request-card .submit-post-link-btn.submitted {
    background-color: #2E302F;
}

.content-request-card .description {
    color: #2E302F;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
}

.content-request-card .decline-campaign-popup,
.content-request-card .accept-campaign-popup,
.content-request-card .submit-post-link-popup {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.content-request-card .decline-campaign-popup .decline-campaign-card,
.content-request-card .accept-campaign-popup .accept-campaign-card {
    border-radius: 8px;
    background: #FFF;
    display: flex;
    gap: 100px;
    flex-direction: column;
    padding: 63px 20px;
    max-width: 500px;
}

.content-request-card .popup-card-heading {
    color: #000;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}

.content-request-card .popup-card-text {
    color: #2E302F;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.content-request-card .accept-campaign-card.email-sent-card {
    text-align: center;
    gap: 24px;
}

.content-request-card .copy-embed-code-card {
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    max-width: 1000px;
}

.content-request-card .copy-done-btn {
    color: #FF614D;
    background-color: white;
    margin-left: auto;
    display: block;
}

.content-request-card .copy-embed-code-card .popup-card-text {
    margin-top: 20px;
}

.content-request-card .copy-embed-code-card .code-input {
    color: #2E302F;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    border-radius: 8px;
    background: #F8EBE9;
    padding: 20px;
    display: block;
    outline: none;
    width: 100%;
    border: none;
    margin-top: 30px;
}

.content-request-card .copy-embed-code-card .copy-code-btn {
    color: #FF614D;
    margin-bottom: 40px;
}

.content-request-card .submit-post-link-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    max-width: 650px;
    text-align: center;
}

.content-request-card .submit-post-link-card .form-container {
    margin: 0 30px;
    padding: 10px;
    text-align: left;
}

.content-request-card .submit-post-link-card .form-container label {
    color: #2E302F;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
}

.content-request-card .submit-post-link-card .form-container input {
    color: #2E302F;
    border-radius: 4px;
    border: 0.7px solid #D4D4D4;
    background: #FCFCFC;
    padding: 16px;
    font-size: 14px;
    margin-bottom: 10px;
}

.content-request-card .submit-post-link-card .form-container input::placeholder {
    color: #9D9A9A;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
}

.content-request-card .post-content-thanks-card {
    background-color: #FDEFED;
    padding: 20px;
    text-align: center;
    max-width: 650px;
    color: #000;
    font-family: "DM Sans", sans-serif;

    position: absolute;
    right: 10px;
    top: 0;
    font-size: 20x;
    font-weight: 500;
    line-height: normal;
    z-index: 20;
}

.content-request-card .post-content-thanks-card .head-text {
    font-size: 24px;
    margin: 16px auto;
}

.content-request-card .post-content-thanks-card.hide {
    display: none;
}

.content-request-card .post-content-thanks-card.show {
    display: block;
}

.content-request-card .close-thanks-card-btn {
    display: block;
    width: fit-content;
    margin-left: auto;
    padding: 5px;
}