.view-campaign{
    background-color: #FAFAFA;
  min-height: 100vh;
}

.view-campaign .view-campaign-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.view-campaign-body .campaign-ca-section{
    width: 90%;
    margin: 4em auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-campaign-body .campaign-ca-section .campaign-back,
.view-campaign-body .campaign-ca-section .start-new-campaign{
    display: flex;
    align-items: center;
    gap: 14px;
    cursor: pointer;
}

.view-campaign-body .campaign-ca-section .campaign-back span{
    color: black;
    font-size: 20px;
    font-family: DM Sans;
}

.view-campaign-body .campaign-ca-section .campaign-back span,
.view-campaign-body .campaign-ca-section .start-new-campaign span{
    display: flex;
    align-items: center;
    justify-content: center;
}

.view-campaign-body .campaign-ca-section .start-new-campaign{
    background-color: #FF614D;
    border-radius: 8px;
    color: #FFF;
    font-size: 14px;
    padding: 18px 24px;
}

.view-campaign-body .campaign-details-card{
    width: 90%;
    margin: 5em auto;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 3em 2em;
}

.view-campaign-body .campaign-details-card .campaign-info h4{
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 600;
    margin-bottom: 8px;
}

.view-campaign-body .campaign-details-card .campaign-info button{
    font-size: 14px;
    padding: 12px 35%;
    background-color: black;
    color: #FFF;
    border-radius: 8px;
}

.view-campaign-body .campaign-details-card .campaign-cost p{
    font-size: 16px;
}

.view-campaign-body .campaign-platforms-table{
    width: 90%;
    margin: 3em auto;
}

.view-campaign-body .campaign-platforms-table table{
    width: 100%;
    border-collapse: collapse;
    background-color: #FFF;
}

.view-campaign-body .campaign-platforms-table table th{
    border-bottom: 3px solid #F9f9f9;
    font-weight: 900;
    font-family: Montserrat;
}

.view-campaign-body .campaign-platforms-table table th,
.view-campaign-body .campaign-platforms-table table td{
    padding: 16px 0;
    text-align: center;
}

@media (min-width: 750px) {
    .view-campaign {
      display: flex;
    }
  
    .view-campaign .view-campaign-main {
      width: 100%;
    }
}