.content-request {
    position: relative;
}

.content-request .head {
    color: #000;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 40px;
}

.content-request .content-request-main-body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}