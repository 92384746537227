.dashboard-campaign-preview {
  margin: 2em 0;
}

.dashboard-campaign-preview table {
  width: 100%;
  border-collapse: collapse;
}

.dashboard-campaign-preview table th {
  background-color: #fff1ef;
  color: #ff614d;
}
.dashboard-campaign-preview table th,
.dashboard-campaign-preview table td {
  padding: 14px 0;
  text-align: center;
  font-size: 14px;
}

.dashboard-campaign-preview table tr.even {
  background-color: #fff;
}

.dashboard-campaign-preview table tr.odd {
  background-color: #f9f9f9;
}

.dashboard-campaign-preview table td img {
  width: 60px;
  aspect-ratio: 1/1;
  object-fit: contain;
}
