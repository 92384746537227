.header-filter-position{
  position: relative;
  margin-top: 26px;
}
.header-filter-position .header-filter {
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  padding: 24px;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between; */
}

.header-filter-position .header-filter .header-heading {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  border-bottom: 1px solid #FF614D;
  width: fit-content;
}

@media (max-width: 750px) {
  .header-filter-position .header-filter .header-heading,
  .header-filter-position .header-filter .back-btn {
    display: none;
  }
}

.header-filter-position .header-filter .header-center {
  max-width: 1000px;
}


@media (min-width: 750px) {
  .header-filter-position .header-filter {
    padding: 24px 60px;
  }
}

.header-filter-position .header-filter .filter-btn {
  color: #FF614D;
  border-radius: 8px;
  border: 2px solid #FF614D;
  padding: 14px 30px;
}

.header-filter-position .header-filter .search-bar-container {
  color: #000;
  border-radius: 20px;
  background: #FAFAFA;
  padding: 20px 16px;
  width: 100%;
  max-width: 600px;
}

.header-filter-position .header-filter .search-platform-input {
  outline: none;
  border: none;
  background-color: transparent;
  color: #000;
}

.header-filter-position .header-filter .search-platform-input::placeholder {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}