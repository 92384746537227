.img-caption-container {
  font-family: 'Poppins',
    sans-serif;
  position: relative;
}

.img-caption-container .caption {
  padding: 23px;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 750px) {
  .img-caption-container .img {
    overflow: hidden;
    border-radius: 8px;
    height: 360px;
  }

  .img-caption-container .caption {
    padding-left: 0;
  }

  .img-caption-container .img img {
    object-fit: cover;
  }

  .img-caption-container .caption {
    background-color: white;
    color: #2E302F;
  }
}

@media (min-width: 750px) {
  .img-caption-container .caption {
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

}