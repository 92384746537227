.dashboard-footer{
    background-color: #212322;
}

.footer-container{
    width: 85%;
    padding: 4em 0 3em 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.footer-container .explore-list{
    list-style: none;
    padding: 0;
}

.footer-container .explore-list h3{
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
}

.footer-container .explore-list li{
    padding-bottom: 10px;
    font-size: 14px;
}

.footer-note{
    color: #fff;
    text-align: center;
    padding-bottom: 1.5em;
}

.social-media-icons{
    padding-top: 1em;
}
.social-media-icons span{
    padding-right: 10px;
}

@media only screen and (max-width: 600px){
    .footer-container{
        width: 95%;
        padding: 4em 0 3em 0;
        margin: 0 auto;
        display: block;
        justify-content: space-between;
        color: #fff;
    }

    .footer-container .explore-list h3{
        font-size: 18px;
        padding-top: 30px;
        color: #FF614D;
    }

    .footer-note p{
        color: #FF614D;
    }
}