.progress-bar .circle {
  width: 70px;
  height: 70px;
  /* background-color: #FAFAFA; */
  border-radius: 50px;
  border: 2px solid #FF681E;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease;
}

.progress-bar .circle::after {
  font-size: 25px;
  color: white;
}

.progress-bar .step {
  background-color: #fff;
  padding: 18px 5px;
}

@media (max-width: 750px) {
  .progress-bar .step {
    background-color: transparent;
    padding: 0px;
  }
}

.progress-bar .steps.one .step-one .circle {
  background-color: #FF614D;
}

.progress-bar .steps.two .step-one .circle {
  background-color: #FF614D;
}

.progress-bar .steps.three .step-one .circle {
  background-color: #FF614D;
}

/* .progress-bar .steps.three .step-one .circle::after {
  content: "\2713";
}

.progress-bar .steps.two .step-one .circle::after {
  content: "\2713";
} */


.progress-bar .steps.two .step-two .circle {
  background-color: #FF614D;
}

.progress-bar .steps.three .step-two .circle {
  background-color: #FF614D;
}

/* .progress-bar .steps.three .step-two .circle::after {
  content: "\2713";
} */

.progress-bar .steps.three .step-three .circle {
  background-color: #FF614D;
}

.progress-bar .steps {
  color: #000;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  line-height: 140%;
  position: relative;
  z-index: 4;
}

.progress-bar .steps .step .step-heading {
  font-size: 20px;
}

.progress-bar .steps .step .step-number {
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 750px) {
  .progress-bar .steps {
    width: 100%;
    justify-content: space-between;
  }

  .progress-bar .steps .step {
    text-align: center;
    flex-direction: column;
  }

  .progress-bar .steps .step .step-heading {
    display: none;
  }

  .progress-bar .steps .animating-progress-container {
    position: absolute;
    width: 100%;
    height: 4px;
    top: 33px;
    z-index: -1;
    background-color: #ff691e41;
    /* #FF614D */
  }

  .progress-bar .steps .animating-bar {
    width: 10px;
    height: 100%;
    background-color: #FF614D;
    transition: all 1s ease;
    /* #FF614D */
  }

  .progress-bar .steps .animating-progress-container.two .animating-bar {
    width: 50%;
  }

  .progress-bar .steps .animating-progress-container.three .animating-bar {
    width: 100%;
  }
}

@media (min-width: 750px) {
  .progress-bar {
    width: 300px;
  }

  .progress-bar .steps {
    flex-direction: column;
    height: fit-content;
    gap: 40px;
  }

  .progress-bar .steps .animating-progress-container {
    position: absolute;
    width: 4px;
    height: 100%;
    left: 33px;
    z-index: -1;
    background-color: #ff691e41;
    /* #FF614D */
  }

  .progress-bar .steps .animating-bar {
    width: 100%;
    height: 10px;
    background-color: #FF614D;
    transition: all 1s ease;
    /* #FF614D */
  }

  .progress-bar .steps .animating-progress-container.two .animating-bar {
    height: 50%;
  }

  .progress-bar .steps .animating-progress-container.three .animating-bar {
    height: 100%;
  }
}