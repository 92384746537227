.homepage-card {
  text-align: center;
  background: #FFF;
  padding: 60px 32px;
  font-weight: 600;
}

.homepage-card h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  line-height: 140%;
  margin: 17px 0;
}

.homepage-card p {
  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
}

@media (min-width: 500px) {
  .homepage-card {
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 5px 40px 0px rgba(146, 196, 255, 0.20);
  }
}