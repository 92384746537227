.overview {
  background-color: #fafafa;
  min-height: 100vh;
}

.overview .overview-main .body {
  padding: 18px;
}

.overview .overview-main .welcome-card {
  border-radius: 8px;
  border: 1px solid #e0dada;
  background: #fff;
  padding: 24px;
  text-align: center;
  margin-top: 50px;
  color: #2e302f;
}

.overview .overview-main .welcome-card .greeting {
  font-size: 24px;
  font-weight: 600;
  line-height: 140%;
  margin: 10px 20px;
}

.overview .overview-main .welcome-card .text {
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
}

.overview .overview-main .welcome-card .add-payout-account-btn {
  padding: 14px 35px;
  border-radius: 8px;
  background: #ff614d;
  color: white;
  margin-top: 30px;
}

.overview .overview-cards-container {
  display: grid;
  gap: 24px;
  margin-bottom: 30px;
}

.overview .campaign-activity-card-container .campaign-card,
.overview .campaign-activity-card-container .activity-card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 5px 40px 0px rgba(146, 196, 255, 0.2);
  padding: 24px;
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  /* min-height: 500px; */
  height: 400px;
  /* overflow: scroll; in case of necessity */
  overflow: hidden;
}

.overview .campaign-activity-card-container .campaign-card [class*="body"],
.overview .campaign-activity-card-container .activity-card [class*="body"] {
  /* background-color: red; */
  height: 80%;
  /* height: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.overview .campaign-card .start-campaign-btn {
  border-radius: 8px;
  background: #ff614d;
  padding: 10px 34px;
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  margin-top: 28px;
}

.activity-card .activities-preview {
  margin: 2em 0;
}

.overview .campaign-activity-card-container .bigger-text {
  font-size: 24px;
}

.overview .campaign-card .head-text {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.overview .activity-card .heading {
  color: #1c1b1f;
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  border-left: 4px solid #ff614d;
  padding-left: 2px;
}

.overview .campaign-card .view-all-link {
  font-size: 16px;
  color: #ff614d;
  margin-inline: 5px;
}

.overview .campaign-activity-card-container {
  display: grid;
  gap: 24px;
}

@media (min-width: 750px) {
  .overview {
    display: flex;
  }

  .overview .overview-main {
    width: 100%;
  }

  .overview .overview-main .body {
    padding: 18px 60px;
  }

  .overview .overview-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .overview .campaign-activity-card-container {
    grid-template-columns: 2fr 1fr;
  }
}
