#error-page {
  background-color: rgba(240, 232, 231, 1);
  min-height: 100vh;
}

#error-page nav {
  background-color: #212322;
}



#error-page .error-container {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 15px;
}

#error-page .error-container h1 {
  font-weight: 700;
  font-size: 40px;
}

#error-page .error-container p {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 25px;
}

#error-page .error-container a {
  border-radius: 8px;
  background-color: #FF614D;
  display: inline-block;
  padding: 16px 20px;
  color: white;
  font-weight: 500;
  line-height: 140%;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  margin-top: 50px;
}

@media (max-width: 750px) {
  #error-page nav {
    background-color: white;
  }
}

@media (min-width: 750px) {
  #error-page .error-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}