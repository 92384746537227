.account-setting-edit-password{
    margin-top: 2em;
    width: 100%;
}

.account-setting-edit-password form{
    width: 100%;
}

.account-setting-edit-password form .form-field{
    width: 100%;
}

.account-setting-edit-password form .form-field label{
    font-size: 16px;
    font-weight: 700;
    display: block;
    margin: 12px 0;
}

.account-setting-edit-password form .form-field input{
    width: 100%;
    border-radius: 4px;
    border: 0.7px solid #D4D4D4;
    background: #FCFCFC;
    color: #9D9A9A;
    padding: 20px 16px;
}

.line-space{
    margin-top: 48px;
}

.account-setting-edit-password form button{
    padding: 20px 16px;
    background-color: #FF614D;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
}

.account-setting-socials{
    margin: 3em 0;
}

.account-setting-socials h3{
    font-size: 20px;
    font-weight: 500;
}

.social-account-details{
    margin: 1em 0;
}

.social-account-details,
.social-account-details .social-acct-name-link{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-account-details .social-acct-name-link{
    gap: 1.5em;
}

.social-account-details .social-acct-name-link p{
    font-size: 16px;
    font-weight: 500;
}

.social-account-details .social-acct-name-link small{
    color: #FF614D;
    font-size: 14px;
}

.social-account-details .social-acct-name-link small a{
    text-decoration: none;
}

.social-account-details button{
    padding: 16px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #2E302F;
    border-radius: 8px;
}

.social-account-details button.connect{
    background-color: #FF614D;
    color: #fff;
    border: 1px solid #FF614D;
    padding: 16px 26px;
}
