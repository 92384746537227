.choose-upload-platform .choose-platforms-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
}

.choose-upload-platform .header-filter {
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  padding: 24px;
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  z-index: 100;
}

.choose-upload-platform .header-filter-container {
  max-width: 750px;
  margin-left: auto;
}

@media (min-width: 750px) {
  .choose-upload-platform .header-filter {
    padding: 24px 60px;
  }
}

.choose-upload-platform .header-filter .filter-btn {
  color: #FF614D;
  border-radius: 8px;
  border: 2px solid #FF614D;
  padding: 20px 30px;
}

.choose-upload-platform .header-filter .search-bar-container {
  color: #000;
  border-radius: 20px;
  background: #FAFAFA;
  padding: 20px 16px;
  width: 100%;
  max-width: 500px;
}

.choose-upload-platform .header-filter .search-platform-input {
  outline: none;
  border: none;
  background-color: transparent;
  color: #000;
}

.choose-upload-platform .header-filter .search-platform-input::placeholder {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
}