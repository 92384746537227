.homepage-footer{
  background-color: #232221;
}

.homepage-footer-container{
  width: 75%;
  padding: 4em 0 3em 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}

.homepage-footer-container .homepage-explore-list{
  list-style: none;
  padding: 0;
}

.homepage-footer-container .homepage-explore-list h3{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.homepage-footer-container .homepage-explore-list li{
  padding-bottom: 10px;
  font-size: 14px;
}

.homepage-footer-note{
  color: #fff;
  /* text-align: center; */
  padding-bottom: 1.5em;
}

.homepage-social-media-icons{
  padding-top: 1em;
}
.homepage-social-media-icons span{
  padding-right: 10px;
}
.homepage-footer-note p {
  /* text-align: center; */
  font-size: 12px;
  font-family: DM Sans;
}

@media (min-width: 750px) {
  .homepage-footer-container {
    flex-direction: row;
    width: 85%;
  }
  .homepage-footer-note {
    text-align: center;
  }
}