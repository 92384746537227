.platform-card {
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
  padding: 24px;
  color: #000;
  font-weight: 400;
}

.platform-card .card-content-container {
  max-width: 300px;
}

.platform-card .platform-card-action-container {
  background: #FFF2EB;
  padding: 24px 16px;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
}

.platform-card .add-btn {
  text-align: left;
}