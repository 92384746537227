.start-campaign {
  background-color: #FAFAFA;
  min-height: 100vh;
}

.start-campaign .start-campaign-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.start-campaign .start-campaign-main .body {
  padding: 18px;
  padding-top: 70px;
  flex: 1;
  text-align: center;
  color: #434144;
}

.start-campaign .start-campaign-main .body .next-btn {
  border-radius: 8px;
  background: #FF614D;
  padding: 20px 50px;
  color: white;
  font-size: 14px;
  line-height: 140%;
  font-family: "DM Sans", sans-serif;
}

.start-campaign .start-campaign-main .body .form-main-container {
  margin: 80px auto 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1000px;
}

.start-campaign .start-campaign-main .body .body-heading {
  font-family: DM Sans;
  font-size: 45px;
  font-weight: 500;
  line-height: 140%;
}

.start-campaign .start-campaign-main .form-main-container .label-container {
  width: fit-content;
  position: relative;
}

.start-campaign .start-campaign-main .form-main-container input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.start-campaign .start-campaign-main .form-main-container input[type="radio"]:checked+.nocampaign-card {
  border: 1px solid #FF614D;
}

.start-campaign .start-campaign-main .form-main-container .nocampaign-card .checkmark-icon-container {
  width: 24px;
  height: 24px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  border: 1px solid #FF614D;

}

.start-campaign .start-campaign-main .form-main-container .nocampaign-card .checkmark {
  opacity: 0;
  color: #FF614D;
  font-size: 26px;
  transform: translate(-2px, -2px);
  transition: all ease .2s;
}

.start-campaign .start-campaign-main .form-main-container input[type="radio"]:checked+.nocampaign-card .checkmark-icon-container {
  border: none;
}

.start-campaign .start-campaign-main .form-main-container input[type="radio"]:checked+.nocampaign-card .checkmark {
  opacity: 1;
}

.start-campaign .start-campaign-main .form-main-container .nocampaign-card {
  padding: 45px;
  background-color: white;
  text-align: center;
  max-width: 750px;
  font-family: DM Sans;
  line-height: 140%;
  font-weight: 500;
  display: block;
  border-radius: 25px;
  border: 1px solid #5B5454;
  cursor: pointer;
  user-select: none;
}

.start-campaign .start-campaign-main .form-main-container .nocampaign-card:focus {
  border: 3px solid black;
}

.start-campaign .start-campaign-main .form-main-container .nocampaign-card .desc-heading {
  color: #000;
  font-size: 24px;
  margin: 10px auto;
}

.start-campaign .start-campaign-main .form-main-container .nocampaign-card .suggestion-text {
  color: #5A5A5A;
  font-size: 20px;
  margin-bottom: 32px;
}

.start-campaign .start-campaign-main .form-main-container .nocampaign-card .start-campaign-btn {
  border-radius: 8px;
  background: #FF614D;
  padding: 20px 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

@media (min-width: 750px) {
  /* .start-campaign {
    display: flex;
  } */

  .start-campaign>.sidenav {
    display: none;
  }

  .start-campaign .start-campaign-main {
    width: 100%;
  }

  .start-campaign .start-campaign-main .body {
    padding: 40px 60px 10px;
  }

  .start-campaign .start-campaign-main .body .form-main-container {
    flex-direction: row;
    overflow: auto;
  }
}