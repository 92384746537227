.blogger-welcome-header button.show {
  display: inline-block;
}

.blogger-welcome-header button.hide {
  display: none;
}

.blogger-welcome-header {
  background-color: white;
  padding: 16px 24px;
}

.blogger-welcome-header .mobile-menu {
  color: #FF614D;
  line-height: 0;
}

.blogger-welcome-header .blogger-profile {
  margin-left: auto;
}

/* .blogger-welcome-header .blogger-avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
} */
.blogger-welcome-header .blogger-avatar {
  width: 50px;
  height: 50px;
  background-color: #FF614D;
  color: #FFF;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  justify-content: center;
  align-items: center;
}

.blogger-welcome-header .heading {
  color: #2E302F;
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}

@media(max-width: 750px) {
  .blogger-welcome-header .heading {
    display: none;
  }
}

@media (min-width: 750px) {
  .blogger-welcome-header {
    padding: 16px 60px;
  }

  .blogger-welcome-header .mobile-menu {
    display: none;
  }
}