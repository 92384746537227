.payment {
  background-color: #FAFAFA;
  min-height: 100vh;
}

.payment .mobile-menu {
  display: none;
}

.payment .payment-main {
  padding: 20px;
  padding-top: 60px;
  /* text-align: center; */
}

.payment .prev-step {
  text-align: left;
}

.payment .payment-main .container .heading {
  color: #2E302F;
  font-family: DM Sans;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
  text-align: center;
  margin-top: 20px;
}

.payment .form-container {
  background-color: white;
  color: #000;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  padding: 20px;
  max-width: 700px;
  margin: 0px auto;
  text-align: left;
}

.payment .form-container .form-head {
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 35px;
}

.payment .form-container input,
.payment .form-container select {
  border-radius: 4px;
  border: 0.7px solid #D4D4D4;
  background: #FCFCFC;
  padding: 16px;
  outline: none;
}

.payment .form-container .pay-btn {
  padding: 24px 12px;
  width: 100%;
  color: white;
  border: 8px;
  background-color: #FF614D;
  font-size: 20px;
  margin-top: 20px;
}

@media (min-width: 750px) {
  .payment .form-container {
    padding: 64px;
  }
}