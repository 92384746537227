.dashboard-navigation{
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    background-color: #fff;
}

.dashboard-navigation .list-navigation-items{
    display: flex;
    list-style: none;
    padding: 0;
    align-items: center;
    gap: 3em;
    font-size: 18px;
}

.dashboard-navigation .list-navigation-items .list-item .link{
    text-decoration: none;
    color: #2E302F;
}

.dashboard-navigation .list-navigation-items .list-item .active{
    color: #FF614D;
}

.dashboard-navigation .list-navigation-items .user-notification{
    display: flex;
    align-items: center;
    gap: 10px;
}

.dashboard-navigation .list-navigation-items .user-notification .user-prefix{
    width: 34px;
    height: 34px;
    background-color: #FF614D;
    border-radius: 50%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: #fff;
}

.dashboard-navigation .list-navigation-items .user-notification .firstname-dropdown{
    display: flex;
    align-items: center;
}

.user-notification-dropdown-icons{
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.firstname-dropdown{
    cursor: pointer;
}