.create-blog-campaign {
  background-color: #FAFAFA;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* .create-blog-campaign .create-blog-campaign-main.page-two {
  padding-top: 100px;
} */

.create-blog-campaign>main {
  flex: 1;
}

.create-blog-campaign .body .body-main {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 24px;
}

.create-blog-campaign .body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 100ms;
  /* overflow: hidden; */
}

.create-blog-campaign .body>div {
  overflow: hidden;
}

.create-blog-campaign .body.show-select-content-type {
  /* position: relative; */
  grid-template-rows: 1fr;
  /* overflow: hidden;
  visibility: hidden; */
}

.create-blog-campaign .mobile-menu {
  display: none;
}

.create-blog-campaign .body .progress-container .circle {
  width: 70px;
  height: 70px;
  background-color: #FAFAFA;
  border-radius: 50px;
  border: 2px solid #FF681E;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease;
}

.create-blog-campaign .body .progress-container .circle::after {
  font-size: 25px;
  color: white;
}

.create-blog-campaign .body .progress-container .steps.one .step-one .circle {
  background-color: #FF614D;
}

.create-blog-campaign .body .progress-container .steps.two .step-one .circle {
  background-color: #FF614D;
}

.create-blog-campaign .body .progress-container .steps.three .step-one .circle {
  background-color: #FF614D;
}

.create-blog-campaign .body .progress-container .steps.three .step-one .circle::after {
  content: "\2713";
}

.create-blog-campaign .body .progress-container .steps.two .step-one .circle::after {
  content: "\2713";
}


.create-blog-campaign .body .progress-container .steps.two .step-two .circle {
  background-color: #FF614D;
}

.create-blog-campaign .body .progress-container .steps.three .step-two .circle {
  background-color: #FF614D;
}

.create-blog-campaign .body .progress-container .steps.three .step-two .circle::after {
  content: "\2713";
}

.create-blog-campaign .body .progress-container .steps.three .step-three .circle {
  background-color: #FF614D;
}

.create-blog-campaign .body .progress-container .steps {
  color: #000;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  line-height: 140%;
  position: relative;
  z-index: 4;
}

.create-blog-campaign .body .progress-container .steps .step .step-heading {
  font-size: 20px;
}

.create-blog-campaign .body .progress-container .steps .step .step-number {
  font-size: 16px;
  font-weight: 400;
}

.body-footer,
.body-footer-loading {
  background: #FFF;
  box-shadow: 0px 5px 40px 0px rgba(146, 196, 255, 0.20);
  padding: 14px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.body-footer button {
  border-radius: 8px;
  background-color: #FF614D;
  color: white;
  padding: 16px 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  font-family: "Dm Sans", sans-serif;
  transition: all .2s ease;
}

.body-footer-loading button{
  line-height: 0;
  color: white;
  background-color: #FF614D;
  border-radius: 8px;
  padding: 1px 30px;
}

.body-footer button:disabled {
  background-color: gray;
}

.create-blog-campaign .select-content-type {
  padding: 24px;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows ease 200ms;
  /* overflow: hidden; */
}

.create-blog-campaign .select-content-type>div {
  overflow: hidden;
}

.create-blog-campaign .select-content-type.hide-select-content-type {
  grid-template-rows: 1fr;
  /* height: 0px;
  visibility: hidden;
  overflow: hidden;
  display: none; */
}

.create-blog-campaign .select-content-type .back {
  color: #FF614D;
  font-size: 20px;
}

.create-blog-campaign .select-content-type .heading {
  color: #000;
  font-family: DM Sans;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
}

/* checkbox */

.create-blog-campaign .select-content-type .form-main-container .label-container {
  width: fit-content;
  position: relative;
}

.create-blog-campaign .select-content-type .form-main-container {
  max-width: 1110px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.create-blog-campaign .select-content-type .form-main-container input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.create-blog-campaign .select-content-type .form-main-container input[type="radio"]:checked+.nocampaign-card {
  border: 1px solid #FF614D;
}

.create-blog-campaign .select-content-type .form-main-container .nocampaign-card .checkmark-icon-container {
  width: 24px;
  height: 24px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  border: 1px solid #FF614D;
}

.create-blog-campaign .select-content-type .form-main-container .nocampaign-card .checkmark {
  opacity: 0;
  color: #FF614D;
  font-size: 26px;
  transform: translate(-2px, -2px);
  transition: all ease .2s;
}

.create-blog-campaign .select-content-type .form-main-container input[type="radio"]:checked+.nocampaign-card .checkmark-icon-container {
  border: none;
}

.create-blog-campaign .select-content-type .form-main-container input[type="radio"]:checked+.nocampaign-card .checkmark {
  opacity: 1;
}

.create-blog-campaign .select-content-type .form-main-container .nocampaign-card {
  padding: 45px;
  background-color: white;
  max-width: 750px;
  font-family: DM Sans;
  line-height: 140%;
  font-weight: 500;
  display: block;
  border: 1px solid #2E302F;
  cursor: pointer;
  user-select: none;
}

.create-blog-campaign .select-content-type .form-main-container .nocampaign-card:focus {
  border: 3px solid black;
}

.create-blog-campaign .select-content-type .form-main-container .nocampaign-card .desc-heading {
  color: #000;
  font-size: 24px;
  margin: 10px auto;
}

@media (max-width: 750px) {
  .create-blog-campaign .body .progress-container .steps {
    width: 100%;
    justify-content: space-between;
  }

  .create-blog-campaign .body .progress-container .steps .step {
    text-align: center;
    flex-direction: column;
  }

  .create-blog-campaign .body .progress-container .steps .step .step-heading {
    display: none;
  }

  .create-blog-campaign .body .progress-container .steps .animating-progress-container {
    position: absolute;
    width: 100%;
    height: 4px;
    top: 33px;
    z-index: -1;
    background-color: #ff691e41;
    /* #FF614D */
  }

  .create-blog-campaign .body .progress-container .steps .animating-bar {
    width: 10px;
    height: 100%;
    background-color: #FF614D;
    transition: all 1s ease;
    /* #FF614D */
  }

  .create-blog-campaign .body .progress-container .steps .animating-progress-container.two .animating-bar {
    width: 50%;
  }

  .create-blog-campaign .body .progress-container .steps .animating-progress-container.three .animating-bar {
    width: 100%;
  }
}

@media (min-width: 750px) {


  .create-blog-campaign .body .body-main {
    flex-direction: row;
    padding: 40px 60px;
  }

  .create-blog-campaign .body .progress-container {
    width: 300px;
  }

  .create-blog-campaign .body .progress-container .steps {
    flex-direction: column;
    height: fit-content;
    gap: 40px;
  }

  .create-blog-campaign .body .progress-container .steps .animating-progress-container {
    position: absolute;
    width: 4px;
    height: 100%;
    left: 33px;
    z-index: -1;
    background-color: #ff691e41;
    /* #FF614D */
  }

  .create-blog-campaign .body .progress-container .steps .animating-bar {
    width: 100%;
    height: 10px;
    background-color: #FF614D;
    transition: all 1s ease;
    /* #FF614D */
  }

  .create-blog-campaign .body .progress-container .steps .animating-progress-container.two .animating-bar {
    height: 50%;
  }

  .create-blog-campaign .body .progress-container .steps .animating-progress-container.three .animating-bar {
    height: 100%;
  }

  .body-footer {
    padding: 24px 60px;
  }

  .create-blog-campaign .select-content-type.hide-select-content-type {
    padding: 100px;
  }
}