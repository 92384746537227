.influencer-complete-signup-page{
    background-color: #FAFAFA;
}
.link-social-media{
    width: 100%;
}
.link-social-media h3{
    text-align: center;
    color: #000;
    font-size: 32px;
    font-weight: 500;
    
}

.social-media-options{
    width: 80%;
    margin: 6em auto 10em auto;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 32px 48px;
    position: relative;
}


.link-social-media .select-social-media{
    display: flex;
    justify-content: space-between;
    margin: 32px 0 15em 0;
}

.link-social-media .select-social-media .social-media-option,
.social-media-options .continue-previous-btns button{
    width: min(250px, 20%);
}
.select-social-media .social-media-option h4{
    text-align: center;
    margin-bottom: 14px;
}
.select-social-media .social-media-option button{
    width: 100%;
}
.select-social-media .social-media-option button,
.social-media-options .continue-previous-btns button{
    border-radius: 8px;
    border: 2px solid #D9D9D9;
    text-align: center;
    padding: 32px 0;
    color: #FF614D;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
}

.select-social-media .social-media-option .media-url,
.social-media-options .continue-previous-btns .media-url{
    background-color: #FF614D;
    border: 2px solid #FF614D;
    color: #fff;
}

.modal-background{
    position: absolute;
    background-color: #fff;
    opacity: 0.5;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.social-media-link-modal{
    position: absolute;
    top: 15%;
    left: 30%;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 2px 2px 10px 3px #aaa;
    padding: 16px;
    z-index: 2;
}

.social-media-link-modal h2{
    text-align: right;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    margin: 10px 0;
}

.modal-content{
    padding: 20px 40px;
}

.modal-content p{
    text-align: center;
}

.modal-content input{
    width: 100%;
    padding: 20px 16px;
    margin: 20px 0;
    border: 1px solid #aaa;
}

.modal-content button{
    display: block;
    width: 100%;
    background-color: #FF614D;
    border: 1px solid #FF614D;
    color: #fff;
    border-radius: 5px;
    padding: 20px 0;
    margin: 32px 0;
    cursor:pointer;
}

.social-media-options .continue-previous-btns{
    display: flex;
    justify-content: flex-end;
    gap: 2em;
    margin-bottom: 2em;
}


.biodata{
    width: max(40%,400px);
    margin: 5em auto;
}

.biodata .blogger-input-form-section-1 form .form-field{
    width: 100%;
    margin: 10px 0;
}

.biodata .blogger-input-form-section-1 form .form-field label{
    display: inline-block;
    margin: 10px 0;
}

.biodata .blogger-input-form-section-1 form .form-field input,
.biodata .blogger-input-form-section-1 form .form-field select,
.biodata .blogger-input-form-section-1 form .form-field .upload{
    width: 100%;
    padding: 20px 16px;
    font-size: 16px;
    border-radius: 5px;
    color: #aaa;
    background-color: #fcfcfc;
    border: 1px solid #d4d4d4;
}

.biodata .blogger-input-form-section-1 form .form-field input[type='submit']{
    display: block;
    width: max(70%,250px);
    margin: 32px auto;
    background-color: #FF614D;
    color: #fff;
    cursor: pointer;
}