.login-page{
    display: flex;
}

.login-section{
    width: 90%;
    min-height: 100vh;
}

.login-section-container{
    width:80%;
    margin: 10em auto;
    color: #18181B;
}

.signup-success-container{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
}

.login-section-container h3{
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
}

.login-section-container p{
    font-size: 15px;
    font-weight: 400;
    line-height: 26px; 
}

.login-section-container form{
    margin-top: 4em;
}

.login-section-container form .form-field{
    width: 80%;
    margin: 10px 0;
}

.login-section-container form .form-field label{
    display: inline-block;
    margin-bottom: 10px;
}

.login-section-container .password-input-container{
    position: relative;
}

.login-section-container .password-input-container .eyes-icon{
    position: absolute;
    right: 2%;
    top: 35%;
}

.login-section-container form .form-field input::placeholder{
    color: #aaa;
}

.login-section-container form .form-field input{
    width: 100%;
    padding: 20px 16px;
    font-size: 16px;
    border-radius: 5px;
    color: black;
    background-color: #fcfcfc;
    border: 1px solid #d4d4d4;
}

.login-section-container form .checkbox-container{
    display: flex;
    gap: 5px;
    cursor: pointer;
}

.login-section-container form .checkbox-container .unchecked-box{
    width: 16px;
    height: 16px;
    border: 1px solid #FF681E;
}

.login-section-container form .checkbox-container .checked-box{
    color: #FF681E;
}

.login-section-container form .checkbox-container .checkbox-text{
    color: #9D9A9A;
    font-size: 14px;
}

.login-section-container .forgot-password{
    display: block;
    color: #FF614D;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-decoration: none;
    margin: 24px 0 48px 0;
}

.login-section-container form .button-submit input,
.login-section-container form .button-submit button{
    background-color: #FF614D;
    width: 100%;
    border-radius: 8px;
    color: #fff;
    border: 1px solid #FF614D;
    cursor: pointer;
}

.login-section-container form .button-submit .disabled-btn{
    opacity: 0.5;
    cursor: default;
}

.login-section-container .dont-have-acct{
    width: 80%;
    text-align: center;
    margin-top: 1.4em;
    font-size: 16px;
    font-weight: 500;
}

.login-section-container .dont-have-acct .link{
    color: #FF614D;
    text-decoration: none;
}

@media only screen and (max-width: 750px) {
  .login-section {
    width: 100%;
    margin-inline: auto;
  }

  .login-section-container {
    width: 90%;
    margin: 0 auto;
  }

  .login-section-container form .form-field {
    width: 100%;
    margin: 10px 0;
  }
}