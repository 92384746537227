.published-content .head {
    color: #000;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 80px;
}

.published-content .table-heading {
    background-color: #fff;
    padding: 20px;
    padding-bottom: 60px;
    color: #000000;
}

@media (min-width: 750px) {
    .published-content .table-heading {
        padding-left: 100px;
    }
}

.published-content .table-heading .search-bar-container {
    background-color: #FFEDE4;
    border-radius: 20px;
    min-width: 200px;
    max-width: 600px;
    padding: 20px 16px;
}

.published-content .table-heading .search-bar-container>input {
    background-color: #FFEDE4;
    outline: none;
    border: none;
    flex: 1;
}

.published-content .table-heading .search-bar-container>input::placeholder {
    color: #000;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
}

.published-content .published-content-table-container {
    overflow-x: auto;
}

.published-content .published-content-table {
    border: 1px solid rgba(129, 44, 3, 0.25);
    min-width: 100%;
    table-layout: fixed;
    border-spacing: 0px;
    border-collapse: collapse;
}

.published-content .published-content-table tr:nth-child(even) {
    background-color: #FFF2EC;
}

.published-content .published-content-table tr:nth-child(odd) {
    background-color: #FFF;
}

.published-content .published-content-table th,
.published-content .published-content-table td {
    color: #000;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    padding: 20px 34px;
    text-align: left;
}

.published-content .published-content-table td {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.published-content .published-content-table th,
.published-content .published-content-table td.type,
.published-content .published-content-table td.status {
    text-transform: uppercase;
}

.published-content .published-content-table .table-view-edit-btn {
    border-radius: 8px;
    background: #FF614D;
    padding: 10px 20px;
    color: #fff;
}