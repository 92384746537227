header.homepage-header {
  min-height: 400px;
}

.home-header-container>div {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  color: #2E302F;
}

.mobile-header-img-div {
  margin-top: 24px;
}

.home-header-container a {
  padding: 16px 25px;
  display: inline-block;
  background-color: #FF614D;
  color: white;
  border-radius: 35px;
  font-weight: 500;
  font-size: 16px;
}

.home-header-container p {
  font-size: 16px;
  font-weight: 400;
  margin: 24px 0;
}

.home-header-container h2 {
  font-family: 'Poppins',
    sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 96%;
}

.homepage-section-container {
  padding: 0 17px;
  margin: 100px auto;
}

.homepage-section-container h2,
.homepage-header h2 {
  font-family: 'Poppins',
    sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}

.homepage-heading-paragraph {
  color: #2E302F;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Nunito Sans', sans-serif;
}

.homepage-campaign-btn {
  font-family: 'DM Sans', sans-serif;
  border-radius: 35px;
  background: white;
  border: 1px solid #FF614D;
  color: #FF614D;
  display: block;
  width: fit-content;
  margin: 0 auto;
  padding: 15px 21px;
}

.homepage-img-caption-card-container {
  margin: 50px 0;
}

.homepage-how-desktop-aside {
  color: #000;

}

.homepage-how-desktop-aside .how-content {
  font-size: 20px;
  font-weight: 700;
  font-family: 'Nunito Sans',
    sans-serif;
}

.homepage-how-container h2 {
  font-family: 'Poppins',
    sans-serif;
  font-size: 24px;
}

.homepage-faq>div>section>section {
  border-bottom: none !important;
  border-radius: 10px;
  box-shadow: 0px 5px 40px 0px rgba(146, 196, 255, 0.20);
  margin: 10px 0;
  padding-left: 24px !important;
}

.homepage-faq>div>section>section span.icon-wrapper {
  background-color: rgba(250, 247, 245, 1);
  border-radius: 50%;
  display: inline-block;
}

.homepage-sub-footer-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-sub-footer {
  color: white;
  text-align: center;
}

.homepage-sub-footer h2 {
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.homepage-sub-footer p {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 40px 0;
}

.homepage-sub-footer a {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  display: block;
  padding: 16px;
  background-color: #FF614D;
  border-radius: 8px;
}

@media (min-width: 750px) {
  header.homepage-header {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 829px;
    overflow: hidden;
  }

  .home-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;
  }

  .home-header-container>div {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: white;
  }

  .home-header-container p {
    font-family: 'Nunito Sans',
      sans-serif;
    font-size: 22px;
    font-weight: 600;
    padding: 0 30px;
  }

  .home-header-container h2 {
    font-family: 'Poppins',
      sans-serif;
    font-size: 76px;
    font-weight: 700;

  }

  .home-easier-span {
    color: #FF614D;
  }

  .home-header-container a {
    background-color: white;
    color: #FF614D;
  }

  .mobile-header-img-div {
    display: none;
  }

  .homepage-section-container {
    padding: 0;
    width: 85%;
    margin: 100px auto;
  }

  .homepage-card-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
  }

  .homepage-card-container>* {
    flex: 1;
  }

  .homepage-section-container h2 {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 140%;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .homepage-how-container h2 {
    color: white;
  }

  .homepage-heading-paragraph {
    font-size: 24px;
  }

  .homepage-campaign-btn {
    color: white;
    background-color: #FF614D;
  }

  .homepage-img-caption-card-container {
    display: flex;
    gap: 24px;
    margin: 50px 0;
    justify-content: center;
  }

  .homepage-how-section {
    background-image: url("../../../assets/images/hg-3.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    display: flex;
  }

  .homepage-mobile-how {
    display: none;
  }

  .homepage-how-desktop-aside {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    margin-left: auto;
    width: 40%;
    padding: 100px;
    text-align: center;
  }

  .homepage-how-desktop-aside .how-content {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 15px
  }

  .homepage-how-container p {
    font-size: 24px;
    font-weight: 400;
    font-family: 'Nunito Sans',
      sans-serif;
  }

  .how-content .how-it-works-row {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito Sans',
      sans-serif;
  }

  .homepage-sub-footer-section {
    min-height: 700px;
  }

  .homepage-sub-footer {
    width: 75%;
    max-width: 1000px;
  }

  .homepage-sub-footer a {
    display: inline-block;
    border-radius: 50px;
    padding: 16px 30px;
  }

  .homepage-sub-footer h2 {
    font-size: 48px;
  }

  .homepage-sub-footer p {
    font-size: 24px;
    font-weight: 600;
  }
}

@media (max-width: 750px) {
  .homepage-how-section {
    padding: 0 20px;
  }

  .homepage-mobile-how {
    font-family: 'Poppins',
      sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .homepage-how-container {
    width: 70%;
    margin: 0 auto;
  }

  .homepage-how-container>h2 {
    display: none;
  }

  .homepage-how-container>p {
    font-family: 'DM Sans',
      sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }

  .homepage-how-icon {
    display: none;
  }

  .how-it-works-row {
    display: block;
    margin-left: 10px;
    border-left: 4px solid rgba(240, 232, 231, 1);
    padding-left: 20px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  .homepage-sub-footer-section {
    padding: 20px;
    min-height: 400px;
    /* background: url("../../../assets/images/wishlist-banner.png"), lightgray -369.403px -307.836px / 323.134% 284.211% no-repeat, rgba(255, 97, 77, 0.27); */
    background-image: url("../../../assets/images/wishlist-banner.png");
    background-position: center;
    background-color: rgba(255, 97, 77, 0.99);
    background-blend-mode: overlay;
    /* background-color: red; */
  }
}