.add-influencer .add-influencer-body {
  background-color: #FAFAFA;
  padding: 24px;
}

.add-influencer .mobile-menu {
  display: none;
}

.add-influencer .footer-next-btn-container,
.add-influencer .add-influencer-header {
  background-color: #E2E2E2;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.add-influencer .add-influencer-header {
  background-color: #fff;
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}

.add-influencer .add-influencer-header .social-media-influencer-text {
  font-size: 18px;
  line-height: 140%;
  border-bottom: 1px solid #FF614D;
}

.add-influencer .add-influencer-header .filter-btn {
  color: #FF614D;
  border: 2px solid #FF614D;
}

.add-influencer .add-influencer-header .search-bar-container {
  border-radius: 20px;
  border: 1px solid rgba(113, 113, 113, 0.32);
  padding: 20px 16px;
  width: 100%;
  max-width: 600px;

  &>.lens-icon {
    cursor: pointer;
  }
}

.add-influencer .add-influencer-header .search-influencers-input {
  border: none;
  outline: none;
  background-color: transparent;

}

.add-influencer .button {
  padding: 20px 35px;
  border-radius: 8px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  width: 100%;
  max-width: 137px;
}

.add-influencer .footer-next-btn-container .next-btn {
  background: #FF614D;
  color: #FFF;
  margin-left: auto;
}

.add-influencer .add-influencer-body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
}

@media (min-width: 750px) {
  .add-influencer .add-influencer-body {
    padding: 100px;
  }

  .add-influencer .footer-next-btn-container,
  .add-influencer .add-influencer-header {
    padding: 20px 100px;
  }

}