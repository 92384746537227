.website-sidebar-l{
    background-color: #F5EEED;
    width: max(300px,40%);
    min-height: 100%;
}

.logo-img-l{
    width: max-content;
    margin: 3em auto;
}

.sidebar-content-l{
    margin: 5em auto 3em auto;
    width: 80%;
}

.sidebar-content-l .selfie-img{
    width: 90%;
    margin: 2em auto;
}

.sidebar-content-l img{
    width: max(300px, 90%);
    display: block;
    margin: auto;
}

.sidebar-content-l p{
    color: #2E302F;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 90%;
    margin: 1em auto 2em auto;
}