.campaign-preview {
  background-color: #FAFAFA;
  min-height: 100vh;
}

.campaign-preview .mobile-menu {
  display: none;
}


/* .campaign-preview .bar {
  background: #F2ECEC;
  padding: 20px;
  position: fixed;
  top: 50%;
  width: 100%;
  z-index: 20;
  flex-wrap: wrap;
} */

/* @media (min-width: 750px) {
  .campaign-preview .bar {
    padding: 24px 60px;
  }
} */

/* .campaign-preview .bar .btn {
  font-family: DM Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  border-radius: 8px;
  background: #FFF;
  padding: 18px 32px;
}

.campaign-preview .bar .btn.back-btn {
  color: #FF614D;
  background-color: #fff;
}

.campaign-preview .bar .btn.next-btn {
  color: #fff;
  background-color: #FF614D;
  margin-left: 30px;
} */

.campaign-preview .main-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  font-family: "DM Sans", sans-serif;
}

.campaign-preview .main-container .heading-text {
  color: #060706;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  max-width: 716px;
  padding: 20px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  border-radius: 8px;
  background: #E3F0E6;
}

.campaign-preview .main-container .container {
  padding: 20px;
  border: 1px solid #9D9A9A;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.campaign-preview .main-container .container p.about-release {
  color: #2E302F;
  font-size: 17.483px;
  font-weight: 500;
  line-height: 140%;
}

.campaign-preview .main-container .container .download-audio-btn {
  width: 300px;
  color: #FFF;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  border-radius: 8px;
  background: #FF614D;
  padding: 24px 45px;
  text-align: center;
  margin-bottom: 30px;
}

.campaign-preview .main-container .container .spotify-preview {
  background: #050505;
  display: flex;
  padding: 16px 15px;
  align-items: center;
  gap: 19px;
  color: white;
}

.campaign-preview .main-container .container .spotify-preview .image {
  width: 144px;
  height: 144px;
  background-position: center;
  background-size: cover;
}

.campaign-preview .main-container .container .spotify-preview .progress-bar-container {
  background: #222;
  padding: 12px 18px;
  align-items: center;
  /* gap: 11px; */
  /* gap: 7px; */
}

.campaign-preview .main-container .container .spotify-preview .progress-bar-container .audio-progress {
  background: #6B6B6B;
  max-width: 295px;
  height: 8px;
  width: 100%;
  margin-right: 20px;
}

.campaign-preview .main-container .container .spotify-preview .progress-bar-container .volume-magnitude {
  background: #FFF;
  max-width: 63px;
  height: 8px;
  width: 100%;
}