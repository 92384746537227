body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
  /* font-family: inherit; */
  font-family: 'DM Sans', sans-serif;
  font-size: inherit;
  font-weight: inherit;
}

img {
  max-width: 100%;
}

button {
  font-family: 'DM Sans', sans-serif;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.direct-column {
  flex-direction: column;
}

.gap {
  gap: 20px;
}

.gap1 {
  gap: 10px;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.flex1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* font-family: 'DM Sans', sans-serif;
font-family: 'Nunito Sans', sans-serif;
font-family: 'Poppins', sans-serif; */